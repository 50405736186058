import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LoadingCircle from '@beelineloans/cx-library/src/components/LoadingCircle';

const Wrap = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

/* Doorvest uses this */
const OneClick = () => {
  const [err, setErr] = useState(false);

  useEffect(() => {
    const body = Object.fromEntries(new URLSearchParams(window.location.search));

    (async (data) => {
      try {
        const response = await fetch('https://hooks.zapier.com/hooks/catch/9500271/bvgvxqo/', {
          method: 'POST',
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          setErr(true);
          throw new Error(response.statusText);
        }
        window.location.href = 'https://beeline.doorvest.com/welcome';
      } catch (error) {
        setErr(true);
        console.error(error);
      }
    })(body);

    return () => { };
  }, []);

  return (
    <Wrap>
      {!err ? <LoadingCircle /> : null}
      {err ? <p>Something has gone wrong, please try again.</p> : null}
    </Wrap>
  );
};

export default OneClick;
